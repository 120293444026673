import { lazy } from 'react'; // React v16.6+; lazy load

const Login = lazy(() => import(/* webpackChunkName: 'Login' */ '../page/login'));
const PersonalCenter = lazy(() => import(/* webpackChunkName: 'PersonalCenter' */ '../page/personalCenter'));
const StationList = lazy(() => import(/* webpackChunkName: 'admin/StationList' */ '../page/admin/stationList'));
const AccountManage = lazy(() => import(/* webpackChunkName: 'admin/AccountManage' */ '../page/admin/accountManage'));
const OperationLog = lazy(() => import(/* webpackChunkName: 'admin/OperationLog' */ '../page/admin/OperationLog'));
const CollectorUpgrade = lazy(() => import(/* webpackChunkName: 'admin/CollectorUpgrade' */ '../page/admin/CollectorUpgrade'));
const PageSetup = lazy(() => import(/* webpackChunkName: 'admin/PageSetup' */ '../page/admin/pageSetup'));
const AddStation = lazy(() => import(/* webpackChunkName: 'admin/AddStation' */ '../page/admin/addStation'));
const MicronetMonitoring = lazy(() => import(/* webpackChunkName: 'station/MicrometMonitoring' */ '../page/station/micronetMonitoring'));
const RunStrategy = lazy(() => import(/* webpackChunkName: 'station/RunStrategy' */ '../page/station/runStrategy'));
const LargeScreenMonitoring = lazy(() => import(/* webpackChunkName: 'station/LargeScreenMonitoring' */ '../page/station/largeScreenMonitoring'));
const GSE = lazy(() => import(/* webpackChunkName: 'station/device/GSE' */ '../page/station/device/GSE'));
const Battery = lazy(() => import(/* webpackChunkName: 'station/device/Battery' */ '../page/station/device/battery'));
const EnergyStorageTank = lazy(() => import(/* webpackChunkName: 'station/device/EnergyStorageTank' */ '../page/station/device/energyStorageTank'));
const KDC = lazy(() => import(/* webpackChunkName: 'station/device/KDC' */ '../page/station/device/KDC'));
const ChargingPile = lazy(() => import(/* webpackChunkName: 'station/device/ChargingPile' */ '../page/station/device/chargingPile'));
const CombinerBox = lazy(() => import(/* webpackChunkName: 'station/device/CombinerBox' */ '../page/station/device/combinerBox'));
const PVInverter = lazy(() => import(/* webpackChunkName: 'station/device/PVInverter' */ '../page/station/device/PVInverter'));
const ElectricMeter = lazy(() => import(/* webpackChunkName: 'station/device/ElectricMeter' */ '../page/station/device/electricMeter'));
const Camera = lazy(() => import(/* webpackChunkName: 'station/device/Camera' */ '../page/station/device/camera'));
const Aircondition = lazy(() => import(/* webpackChunkName: 'station/device/Aircondition' */ '../page/station/device/Aircondition'));
const STS = lazy(() => import(/* webpackChunkName: 'station/device/STS' */ '../page/station/device/STS'));
const STSV32 = lazy(() => import(/* webpackChunkName: 'station/device/STSV32' */ '../page/station/device/STSV32'));
const EnergyStatistic = lazy(() => import(/* webpackChunkName: 'station/report/EnergyStatistic' */ '../page/station/reportForm/energyStatistics'));
const FaultAlarm = lazy(() => import(/* webpackChunkName: 'station/report/FaultAlarm' */ '../page/station/reportForm/faultAlarm'));
const ScreenLogs = lazy(() => import(/* webpackChunkName: 'station/report/ScreenLogs' */ '../page/station/reportForm/screenLogs'));
const StationManagement = lazy(() => import(/* webpackChunkName: 'station/StationManagement' */ '../page/station/stationManagement'));
const Error404 = lazy(() => import(/* webpackChunkName: 'Error404' */ '../page/error404'));

export default [
    { path: '/', authorization: true },
    { path: '/admin', component: StationList, authorization: true },
    { path: '/login', component: Login, authorization: false },
    { path: '/404', component: Error404, authorization: false },
    { path: '/personalCenter', component: PersonalCenter, authorization: true },
    { path: '/admin/accountManage', component: AccountManage, authorization: true },
    { path: '/admin/OperationLog', component: OperationLog, authorization: true },
    { path: '/admin/CollectorUpgrade', component: CollectorUpgrade, authorization: true },
    { path: '/admin/pageSetup', component: PageSetup, authorization: true },
    { path: '/admin/addStation', component: AddStation, authorization: true },
    { path: '/admin/editPowerStation', component: AddStation, authorization: true },
    { path: '/station', component: MicronetMonitoring, authorization: true },
    { path: '/station/runStrategy', component: RunStrategy, authorization: true },
    { path: '/station/largeScreenMonitoring', component: LargeScreenMonitoring, authorization: true },
    { path: '/station/device/PCS', component: GSE, authorization: true, deviceType: 'pcs', deviceDriver: 'pcs' },
    { path: '/station/device/GSE', component: GSE, authorization: true, deviceType: 'gse', deviceDriver: 'gse' },
    { path: '/station/device/GSE-TS', component: GSE, authorization: true, deviceType: 'gse_ts', deviceDriver: 'gse_ts' },
    { path: '/station/device/KAC', component: GSE, authorization: true, deviceType: 'kac', deviceDriver: 'kac' },
    { path: '/station/device/GSE-T', component: GSE, authorization: true, deviceType: 'gse_t', deviceDriver: 'gse_t' },
    { path: '/station/device/battery', component: Battery, authorization: true, deviceType: 'bms', deviceDriver: 'bms' },
    { path: '/station/device/battery_gt', component: Battery, authorization: true, deviceType: 'bms_gt', deviceDriver: 'bms_gt' },
    { path: '/station/device/battery_new', component: Battery, authorization: true, deviceType: 'bms_new', deviceDriver: 'bms_new' },
    { path: '/station/device/battery_tbd', component: Battery, authorization: true, deviceType: 'bms_tbd', deviceDriver: 'bms_tbd' },
    { path: '/station/device/energyStorageTank', component: EnergyStorageTank, authorization: true },
    { path: '/station/device/KDC-PV', component: KDC, authorization: true, deviceType: 'kdc', deviceDriver: 'kdc-1' },
    { path: '/station/device/KDC-BAT', component: KDC, authorization: true, deviceType: 'kdc', deviceDriver: 'kdc-2' },
    { path: '/station/device/combinerBox', component: CombinerBox, authorization: true },
    { path: '/station/device/chargingPile', component: ChargingPile, authorization: true },
    { path: '/station/device/PVInverter', component: PVInverter, authorization: true },
    { path: '/station/device/electricMeter', component: ElectricMeter, deviceType: 'meter', authorization: true },
    { path: '/station/device/electricMeterKAC', component: ElectricMeter, deviceType: 'meter_kac', authorization: true },
    { path: '/station/device/electricMeterGB', component: ElectricMeter, deviceType: 'meter_gb', authorization: true },
    { path: '/station/device/camera', component: Camera, authorization: true },
    { path: '/station/device/air', component: Aircondition, deviceType: 'air', authorization: true },
    { path: '/station/device/air_yl', component: Aircondition, deviceType: 'air_yl', authorization: true },
    { path: '/station/device/air_bg', component: Aircondition, deviceType: 'air_bg', authorization: true },
    { path: '/station/device/STS', component: STS, authorization: true },
    { path: '/station/device/STSV32', component: STSV32, authorization: true },
    { path: '/station/reportForm/energyStatistics', component: EnergyStatistic, authorization: true },
    { path: '/station/reportForm/faultAlarm', component: FaultAlarm, authorization: true },
    { path: '/station/reportForm/screenLogs', component: ScreenLogs, authorization: true },
    { path: '/station/management', component: StationManagement, authorization: true },
    // { path: '/emailBinding', component: , authorization: true },
];
